
function displayInt(number) {
    var vAsDec = number + 0.00;
    var AmountWithCommas = vAsDec.toLocaleString();
    var DecimalSeparator = Number("1.2").toLocaleString().substr(1, 1);
    var arParts = String(AmountWithCommas).split(DecimalSeparator);
    var intPart = arParts[0];
    return intPart;
}

var acorn = {};

acorn.showReport = function (obj) {

    var loadReport = function (container) {
        return $(container).telerik_ReportViewer({
            serviceUrl: "/api/ReportsApi/",
            templateUrl: '/ReportViewer/templates/telerikReportViewerTemplate-9.1.15.731.html',
            reportSource: {
                report: obj.name,
                parameters: obj.params
            },
            viewMode: telerikReportViewer.ViewModes.INTERACTIVE,
            scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
            scale: 1.0,
            ready: function () {
                if (obj.onLoad) {
                    obj.onLoad();
                }
            }
        }).data('telerik_ReportViewer');
    }

    if (!obj.container) {
        $('<div>Loading Report - Please Wait.....</div>').dialog({
            width: 1000,
            height: 600,
            title: obj.title,
            open: function _onOpen(event, ui) {
              return  loadReport(this);
            },
            resize: function () {
                // $(this).dialog('option', 'position', { my: "center" });
            }
        });
    } else {
        return loadReport(obj.container);
    }
}