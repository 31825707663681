ko.bindingHandlers.datepicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        //initialize datepicker with some optional options
        var options = allBindingsAccessor().datepickerOptions || {};

        if (!options.showTime) {
            options.showTimepicker = false;
            options.showButtonPanel = false;
        }

        var datePlugin = 'datetimepicker';
        $(element)[datePlugin](options);

        //handle the field changing
        //ko.utils.registerEventHandler(element, "change", function () {
        //    var observable = valueAccessor();
        //    console.log(element)
        //    observable($(element).val());
        //});

        $(element)[datePlugin]("option", "onSelect", function (dateText, inst) {
         
            var observable = valueAccessor();
            observable(dateText);
            if (options.showButtonPanel === false) {
                inst.input.datepicker("hide");
            }
        });

        $(element)[datePlugin]("option", "onClose", function (dateText, inst) {
            var observable = valueAccessor();
            observable(dateText);
        });

        //$(element).on('blur', function () {
        //    debugger;
        //    var observable = valueAccessor();
        //    observable($(this).val());
        //});

        //handle disposal (if KO removes by the template binding)
        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element)[datePlugin]("destroy");
        });

    },
    update: function (element, valueAccessor, allBindingsAccessor) {
    
    
        var value = ko.utils.unwrapObservable(valueAccessor()),
            options = allBindingsAccessor().datepickerOptions || {},
            datePlugin = options.showTime ? 'datetimepicker' : 'datepicker';

        //handle date data coming via json from Microsoft
        if (String(value).indexOf('/Date(') == 0) {
            value = new Date(parseInt(value.replace(/\/Date\((.*?)\)\//gi, "$1")));
        } 
       
       $(element)[datePlugin]("setDate", value);
       
    }
};