

editor.module = null;

function editor(config) {

    var ext = {},
        element = null,
        oExtraParams = null,
        saveCallback= null,
        params = function () {
            var args = Array.prototype.slice.call(arguments, 0);
            var merged = $.extend.apply(this, [{}, ext.oLoadParams, oExtraParams].concat(args));
            return { arr: $.toRequestArray(merged), obj: merged };
        },
        fillParams = {},
        loading = function () {
            return $('<div></div>').dialog({
                autoOpen: false,
                closeOnEscape: false,
                width: 100,
                draggable: false,
                resizable: false,
                modal: true,
                open: function () {
                    $(this).spin({ length: 0, width: 15, radius: 40 });
                },
                create: function () {
                    var widget = $(this).dialog('widget');
                    //remove default title bar
                    $('.ui-dialog-titlebar', widget).remove();


                }
            });
        },
        loader=null,
        feedback = function (show) {
            if (ext.bIsInline) {
                if (show !== false)
                    ext.oTarget.spin({ length: 0, width: 12, lines: 12, speed: 1.1, radius: 30 });
                else
                    ext.oTarget.spin(false);

                return;
            }

            if (show !== false) {
                if (loader == null) { loader = loading(); }
                loader.dialog('open');
            }
            else {
                loader.dialog('close');
            }
        },
        busy = function (show) {
            if (show !== false)
                element.spin({ length: 0, width: 12, lines: 12, speed: 1.1, radius: 30 });
            else
                element.spin(false);
        },
        template = function () {
            return '<div class="editor-container hide" >' +
                            ' <div class="editor-header">' +
                            '      <i class="icon-remove pull-right editor-close "></i>' +
                        
                            '      <h4  class="editor-title"> </h4>' +
                          
                            '  </div>' +
                           
                            '  <div class=" editor-body"> ' +
                            '       <form class="editor-form"></form>' +
                            '       <div class="editor-error-container alert alert-error hide">' +
                            '           <ul class="editor-errors"></ul>' +
                            '       </div>' +
                            '       <div class="editor-status-container alert alert-info hide">' +
                            '           <ul class="editor-status"></ul>' +
                            '       </div>' +
                            '  </div>' +
                            '  <div class="editor-footer">' +
                            '      <div class="pull-right editor-buttons"> ' +
                            '          <button class="btn editor-close" >[Cancel]</button> ' +
                            '          <button class="btn btn-primary editor-save">[Save]</button> ' +
                            '      </div> ' +
                            '  </div> ' +
                            '</div>';
        },
        templateInline = function () {
            return '<div class="editor-container" >' +

                            '  <div class=" editor-body"> ' +
                            '       <div class="editor-error-container alert alert-error hide">' +
                            '           <ul class="editor-errors"></ul>' +
                            '       </div>' +
                            '       <div class="editor-status-container alert alert-info hide">' +
                            '           <ul class="editor-status"></ul>' +
                            '       </div>' +
                            '       <form class="editor-form"></form>' +

                            '  </div>' +
                            '  <div class="editor-footer clearfix">' +
                            '      <div class="pull-right"> ' +
                            '          <a href="#" class="btn btn-primary editor-save">[Save]</a> ' +
                            '      </div> ' +
                            '  </div> ' +
                            '</div>';
        },
        init = function () {
            if (ext.bIsInline) {
                initInline();
                return;
            }

            if (element) {
                element.remove();

            }

            var t = template();
            $.each( ext.oLanguage, function(key, value) {
                t = t.replace("[" + key + "]", value);
            });

            element = $(t);
            ext.oTarget.append(element);
           
            element.dialog({
                autoOpen: false,
                closeOnEscape: false,
                width: ext.width,
                modal: true,
                draggable: false,
                resize: function (event, ui) {
                    center();
                    _trigger("resize");
                },
                create: function () {
                    var widget = $(this).dialog('widget');
                    //remove default title bar
                    $('.ui-dialog-titlebar', widget).remove();

                    widget.css('padding', 0);

                    $('.ui-dialog-content', widget).css('padding-top', 0);

                    //Bind custom close element in custom title
                    el('.editor-close').click(function (e) {
                        element.dialog('close');
                    });

                    el('.editor-header').css('cursor', 'pointer');

                    widget.draggable({
                        handle: '.editor-header'
                    });

                }
            });

            //No scrollbars 
          //  el('.editor-body').css('overflow-y', 'visible');

            //Bind save button
            el('.editor-save').click(function (e) {
                e.preventDefault();
                ext.fnSubmit();

            });

            //bind close buttons
            el('.editor-close').click(function (e) {
                e.preventDefault();
                ext.fnClose();
            });

            el('.editor-header').css('cursor', 'pointer');

      

        },
        initInline = function () {
            if (element) {
                element.remove();
            }

            var t = templateInline();
            $.each(ext.oLanguage, function (key, value) {
                t = t.replace("[" + key + "]", value);
            });

            element = $(t);



            ext.oTarget.append(element);

            //Bind save button
            el('.editor-save').click(function (e) {
                e.preventDefault();
                ext.fnSubmit();

            });

        },
        el = function (selector) { return $(selector,element[0]) },
        fileParams = function () {
            var files = ext.fnGetFiles(element);
            var fileList = { obj: {}, arr: [] };

            $.each(files, function (key, f) {
                if (f.val() != '' && f[0].files.length > 0) {

                    fileList.obj[key] = f[0].files[0].name; //for validation
                    fileList.arr.push({ name: key, value: f[0].files[0] });
                }
            });

            return fileList;
        },
        handleError = function (xhr, status, err, callback,title) {
            var Errors = [err];

            try {
                r = JSON.parse(xhr.responseText); //this could through an exception
                if (r.Errors) {
                    Errors = r.Errors;
                }
            }
            catch (e) {
                if (console)
                    console.log(e.message);
            }
            finally {
                
                callback(Errors, xhr, xhr.status);

                ext.fnDisplayErrors(Errors,title);
            }
        },
        prefill = function (data) {
            if (data) {
                fillParams = data;
            }

            if (element && element instanceof jQuery) {
                $.each(fillParams, function (key, value) {
                    if(value !=0 && $.trim(value) != ''){
                        $('input[name="' + key + '"],select[name="' + key + '"],textarea[name="' + key + '"]', element).val(value);
                    }
                });
                ext.fnFill(fillParams); //custom fills
                fillParams = {};
            }
        },
        exec = function (action, args) {
            var o = args || {};
            o.element = element;
            ext.actions[action].call(null,o);
        },
        disable = function (all) {
            if (all === true) {
                el('input,textarea,select,button,a.btn').attr('disabled', 'disabled');
            }
            else {
                el('input:not(.read-only-ignore),textarea:not(.read-only-ignore),select:not(.read-only-ignore),button:not(.read-only-ignore),a.btn:not(.read-only-ignore)').attr('disabled', 'disabled');
            }
            el('a.btn').click(function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
            });
            ext.fnDisable({ element: element, all:all });
        },
        center = function () {
            if (!ext.bIsInline) {
                element.dialog('option', 'position', { my: "center" });
            }

        },
        _events = {},
        _trigger = function (eventName, args) {
            $(_events).trigger(eventName, args);
        },
        _on = function (eventName, fn) {
            $(_events).on(eventName, fn);
        },
        _off = function (events, sel, fn) {
            $(_events).off(events, sel, fn);
        };



    //default config
    var base = {
        width: 560,
        sLoadUrl: null,
        sSaveUrl: null,
        sUpdateUrl: null,
        sRemoveUrl: null,
        actions:{},
        sTitle: '',
        zIndex:1060,
        bIsInline: false,
        bAutoLoad:true,
        bDisabled: false,
        bDisableAll: false,
        bHideSave:false,
        oTarget: $('body:first'),
        fnGetParams: function (element) { return {} }, //Must override
        fnGetErrors: function (request) { return [] },
        fnFill: function (params) { },
        oLanguage: {Save: 'Save', Cancel: 'Cancel'},
        oLoadParams: {},
        onLoad: function () { },
        onLoadError: function (err, xhr, status) { },
        onPreLoad: function () { },
        onPreUpdate: function () { },
        onUpdate: function () { },
        onUpdateError: function (errors) { },
        onClose: function () { },
        onPreSave: function (request, onCanSave) { onCanSave(); },
        onSave: function (data,req) { },
        onSaveError: function (err, xhr, status) { },
        onPreRemove: function (errors) { },
        onRemove: function () { },
        onRemoveError: function (err, xhr, status) { },
        fnInit: function (o) { },
        fnDisable:function(o){ },
        fnClose: function (cancel) {
            element.dialog('close');
            ext.onClose();
            element.dialog('destroy');
            element.remove();
        },
        fnPreSave: function (request, onCanSave) {

            var errors = ext.fnGetErrors(request);


            if (!el('.editor-form').valid()) {
                $.each(el('.editor-form').validate().errorList, function (index, item) {
                    errors.push(item.message);
                });
            }

            if (errors.length > 0) {
                ext.fnDisplayErrors(errors);
            }
            else {
                ext.onPreSave(request, onCanSave);
            }

            
        },
        fnDisplayErrors: function (errors,title) {
            var html = '';

            $.each(errors, function (index, item) {
                html = html + '<li>' + item + '</li>';
            });

            if (!(element instanceof jQuery) || !element.is(':visible')) {
                jAlert('<ul >' + html + '</ul>', title);
            }
            else {

                el('.editor-errors').html(html);
                el('.editor-errors').parent().slideDown();
            }
        },
        fnDisplayStatus: function (messages, title) {
            var html = '';

            $.each(messages, function (index, item) {
                html = html + '<li>' + item + '</li>';
            });

            el('.editor-status').html(html);
            if (!el('.editor-status').parent().is(':visible')) {
                el('.editor-status').parent().slideDown();
            }
        },
        fnHideStatus: function () { el('.editor-status').parent().hide(); },
        fnHideErrors: function () { el('.editor-errors').parent().hide(); },
        fnLoadForm: function (html) {
            init();

            el('.editor-form').html(html).css('margin',0);

            //override base from form
            if (!editor.module) {
                ext.fnDisplayErrors(["Failed to load module at:" + ext.sLoadUrl], 'Application Error');
                throw new Error("Failed to load module at:" + ext.sLoadUrl);
            };
            //second mixin
            ext = $.extend(true,{}, base, editor.module, config);

            //reset module
            editor.module = null;

            $.validator.unobtrusive.parse(el('.editor-form')[0]); //bind unobtrusive validation

            //set prefill values
            prefill();

            if (!ext.bIsInline) {
                element.dialog('open');
                element.dialog("option", "width", ext.width);
                if (ext.height) {
                    element.dialog("option", "height", ext.height);
                }
                center();
            }

            //Set header content
            if (ext.sTitle && ext.sTitle != '') {
                el('.editor-title').html(ext.sTitle);
            }


            if (ext.bHideSave) {
               el('.editor-footer').remove();
            }

            element.keypress(function (e) {
               
                if (e.keyCode == $.ui.keyCode.ENTER && e.target.nodeName.toLowerCase() != 'textarea') {
                    element.focus();
                    ext.fnSubmit();
                }
            });

            ext.fnInit({
                element: element,
                params: function () {
                    return $.extend({},params().obj,ext.fnGetParams(element));
                },
                disable: disable,
                busy: busy,
                trigger: _trigger, //I think this is preferred over present actions override
                on: _on,
                center: center,
                bDisabled: ext.bDisabled,
                submit: ext.fnSubmit,
                close: ext.fnClose,
                showErrors: ext.fnDisplayErrors,
                hideErrors: ext.fnHideErrors,
                showStatus: ext.fnDisplayStatus,
                hideStatus: ext.fnHideStatus
            });

            if (ext.bDisabled) {
                disable(ext.bDisableAll);
            }


        },
        fnRemove: function () {
            feedback();
            var removeParams = params();
            ext.onPreRemove(removeParams.obj);

            $.ajax({
                url: ext.sRemoveUrl,
                type: "POST",
                dataType: "json",
                data: removeParams.arr,
                success: function (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        if (!$.isArray(data.Errors)) { data.Errors = [data.Errors]; }
                        ext.onRemoveError(data.Errors);
                        ext.fnDisplayErrors(data.Errors);
                    } else {
                        ext.onRemove(removeParams.obj, data);
                    }
                },
                error: function (xhr, status, err) {
                    handleError(xhr, status, err, ext.onRemoveError,'Error Deleting Item');
                }
            }).always(function () {
                feedback(false);
            });

        },
        fnUpdate: function (url) {
            feedback();
            var updateParams = params();
            ext.onPreUpdate(updateParams.obj);

            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: updateParams.arr,
                success: function (data) {

                    if (data.Errors && data.Errors.length > 0) {
                        if (!$.isArray(data.Errors)) { data.Errors = [data.Errors]; }
                        ext.onUpdateError(data.Errors);
                        ext.fnDisplayErrors(data.Errors);
                    } else {
                        ext.onUpdate(updateParams.obj, data);
                    }

                    
                },
                error: function (xhr, status, err) {
                    handleError(xhr, status, err, ext.onUpdateError, 'Request Error');
                }
            }).always(function () {
                feedback(false);
            });

        },
        fnGetForm: function () {
            feedback();
            var loadParams = params();
            ext.onPreLoad(loadParams.obj);

            $.ajax({
                url: ext.sLoadUrl,
                type: "GET",
                data: loadParams.arr,
                success: function (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        if (!$.isArray(data.Errors)) { data.Errors = [data.Errors]; }
                        ext.onLoadError(data.Errors);
                        ext.fnDisplayErrors(data.Errors,"Loading Error");
                    } else {
                        ext.fnLoadForm(data);
                        ext.onLoad(loadParams.obj, data, element);
                    }
                    feedback(false);
                },
                error: function (xhr, status, err) {
                    feedback(false);
                    handleError(xhr, status, err, ext.onLoadError, 'Error Loading Form');
                }
            });

        },
        fnSubmit: function () {
            ext.fnHideErrors();
            //get data from module
            var formdata = ext.fnGetParams(element);
            //Get files for upload
            var filedata = fileParams();
            //convert to array for submission
            var saveParams = params(formdata);

            //before save hook.Error/validation
            var requestObj = $.extend({}, filedata.obj, saveParams.obj);
           
            ext.fnPreSave(requestObj, function(){

                ext.fnSubmitWithFiles(saveParams.arr.concat(filedata.arr), saveParams.obj);
            });

        },

        fnSubmitWithFiles: function (data,req) {

            busy();

            var fd = new FormData();

            $.each(data, function (index, item) {
                if (item.value != null) {
                    fd.append(item.name, item.value);
                }
                
            });

            $.ajax({
                url: ext.sSaveUrl,
                type: "POST",
                data: fd,
                cache: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    if (data.ListErrors) {
                        ext.fnDisplayListErrors(data.ListErrors);
                    }

                    if (data.Errors) {
                        ext.onSaveError(data.Errors);
                        ext.fnDisplayErrors(data.Errors);

                    }

                    if (!data.ListErrors && !data.Errors) {
                        ext.fnDirtyReset(data, element);
                        ext.onSave(data, req);
                        if (saveCallback) {
                            saveCallback(data);
                        }
                        if (!ext.bIsInline)
                            ext.fnClose(data);
                    }
                },
                error: function (xhr, status, err) {
                    handleError(xhr, status, err, ext.onSaveError);
                }
            }).always(function () {
                busy(false);
            });

        },
        fnDisplayListErrors: function (data) { },
        fnIsDirty: function (o) { return false; },
        fnDirtyReset: function (o) { },
        fnReset: function(o){},
        fnGetFiles: function (element) { return {}; } //must override if you intend to upload files
    };

    //Inital mixins
    ext = $.extend({}, base, config);

    if (ext.bIsInline && ext.bAutoLoad) {
        ext.fnGetForm();
    }
    //Return anonymous object with fluent public methods
    return fluent = {
        //call this to view/add/edit entities
        load: function (params) {
            oExtraParams = params || {};
            ext.fnGetForm();
            return fluent;
        },
        //call this to delete entities
        remove: function (params, confirm) {
            oExtraParams = params || {};

            if (confirm === false) {
                ext.fnRemove();
            }
            else {
                jConfirm("Are you sure you want to delete this item", "Delete Confirmation",
                    function (ok) {
                        if (ok)
                            ext.fnRemove();
                    });
            }

            return fluent;
        },
        save: function (o) {
            saveCallback = o && o.callback ? o.callback : null;
            oExtraParams = o && o.params? o.params : {};
            ext.fnSubmit();
            return fluent;
        },
        action: function (params, url) {
            oExtraParams = params || {};
            var updateUrl = url || ext.sUpdateUrl;
            ext.fnUpdate(updateUrl);
            return fluent;
        },
        fill: function (params) {
            prefill(params);
            return fluent;
        },
        isDirty: function () {
            return ext.fnIsDirty({ showErrors: ext.fnDisplayErrors, hideErrors: ext.fnHideErrors });
  
        },
        reset: function () {
            ext.fnHideErrors();
            ext.fnReset({element:element});
            return fluent;
        },
        call: function (action, arguments) {
            exec(action, arguments);
            return fluent;
        },
        collapsed: function (collapse, callback) {
            var animationPromise;
            if (collapse === false) {
                animationPromise = element.slideDown().promise();
            }
            else {
                animationPromise = element.slideUp().promise();
            }

            if (callback) {
                animationPromise.done(callback);
            }

            return fluent;
        },
        on: function (eventName, fn) {
            _on(eventName, fn);

            return fluent;
        },
        trigger: function(eventName,args){
            _trigger(eventName, args);
        },
        off : function (events, sel, fn) {
            _off(events, sel, fn);
        },
        disable: function (all) {
            disable(all);
            return fluent;
        }

    }
}

